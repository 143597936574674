<template>
  <div>
    <CCard>
      <CCardHeader>
        <slot name="header">
          KPI's de Ventas
          <div class="card-header-actions" style="width: 600px; margin-right: 18px;">
            <CRow>     
              <CCol md="8" style="text-align: right; padding-right: 0px;">                
              </CCol>                      
              <CCol md="4" style="text-align: right; padding-right: 0px;">
              </CCol>       
            </CRow>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow>      
          <CCol md="12">
              <CRow>                                           
                <CCol md="4">
                  <CCard>
                    <SellersWithSales ref="graphGauge" title="Vendedores con Ventas" :month="month"></SellersWithSales>
                  </CCard>      
                </CCol>     
                <CCol md="4">
                  <CWidgetSimple style="height: 280px;">
                    <CRow>  
                      <CCol md="12"> 
                        <CustomersReached ref="graphDonout" title="Clientes Alcanzados:" :month="month"></CustomersReached>
                      </CCol> 
                    </CRow>
                  </CWidgetSimple>
                </CCol>     
                <CCol md="4">
                  <MonthSales ref="monthSales" title="Ventas del Mes:" :month="month"></MonthSales>
                </CCol>     
              </CRow>              
          </CCol>                   
        </CRow>
        <CRow>             
          <CCol md="6">
            <CedisPerformance ref="cedisPerformance" title="Desempeño por CEDIS"></CedisPerformance>
          </CCol>                       
          <CCol md="6">
            <MonthPerformance ref="monthPerformance" title="Venta por Mes"></MonthPerformance>
          </CCol>  
        </CRow>
        <CRow>             
          <CCol md="12"><br/><br/><br/></CCol>  
        </CRow>
        <CRow>          
          <CCol md="12">
            <ProjectionTable ref="projectionTable" title="Venta por Mes"></ProjectionTable>
          </CCol>                       
        </CRow>
        <CRow>             
          <CCol md="12"><br/><br/><br/></CCol>  
        </CRow>
        <CRow>          
          <CCol md="12" class="text-center">
            <h3>Participación en Distrbución Clientes PCZ Mismo Mes Año anterior</h3>
          </CCol>      
          <CCol md="6">
            <SalesVsTarget ref="salesVsTarget" title=""></SalesVsTarget>
          </CCol>      
          <CCol md="6">
            <CRow>          
              <CCol md="6" class="text-center">
                <GaugePercentGraph ref="gaugePercentGraph" title=""></GaugePercentGraph>                
              </CCol>                       
              <CCol md="6" class="text-center">
                <GaugePercentGraph ref="gaugePercentGraph2" title=""></GaugePercentGraph>                
              </CCol>                       
            </CRow>     
            <br>
            <br> 
            <CRow>                           
              <CCol md="4">
                <CWidgetSimple :text="String(1990)"></CWidgetSimple>
              </CCol>
              <CCol md="4">
                <CWidgetSimple :text="String(1572)"></CWidgetSimple>
              </CCol>
              <CCol md="4">
                <CWidgetSimple :text="String(18370)"></CWidgetSimple>
              </CCol>
            </CRow>
            <CRow> 
              <CCol md="4">
                <CWidgetSimple style="background-color: #3465a4; color: #fff" :text="String(2024)"></CWidgetSimple>
              </CCol>
              <CCol md="4">
                <CWidgetSimple style="background-color: #3465a4; color: #fff" :text="String(2023)"></CWidgetSimple>
              </CCol>
              <CCol md="4">
                <CWidgetSimple style="background-color: #3465a4; color: #fff" text="Total PCZ"></CWidgetSimple>
              </CCol>
            </CRow>
          </CCol>                       
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>

import store from '../../store';
import SellersWithSales from '../../components/graphs/SellersWithSales.vue';
import MonthSales from '../../components/graphs/MonthSales.vue';
import CedisPerformance from '../../components/graphs/CedisPerformance.vue';
import MonthPerformance from '../../components/graphs/MonthPerformance.vue';
import SalesVsTarget from '../../components/graphs/SalesVsTarget.vue';

import DonoutGraph from '../../components/graphs/DonoutGraph.vue';
import GaugePercentGraph from '../../components/graphs/GaugePercentGraph.vue';
import CustomersReached from '../../components/graphs/CustomersReached.vue';
import DonoutGraphMini from '../../components/graphs/DonoutGraphMini.vue';
import StackedBarGraph from '../../components/graphs/StackedBarGraph.vue';
import DoubleBarVerticalGraph from '../../components/graphs/DoubleBarVerticalGraph.vue';
import HomeMenu from '../../components/menu/HomeMenu.vue';
import ProjectionTable from '../../components/graphs/ProjectionTable.vue';

export default {
  name: 'Dashboard',
  components: {
    MonthSales,
    CustomersReached,
    SellersWithSales,
    HomeMenu,
    StackedBarGraph,
    CedisPerformance,
    MonthPerformance,
    DoubleBarVerticalGraph,
    GaugePercentGraph,
    SalesVsTarget,
    ProjectionTable
  },
  props: {
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data () {
    return {
      items: [],      
      month: "202411"
    }
  },
  mounted: async function() {
    let role = localStorage.getItem("role");

    if(role != 'administrator'){
      if(role == "supplier"){
        window.location.href = "/#/welcome"; return;
      }
      else{
        if(role == "manager"){
          window.location.href = "/#/welcome"; return;
        }
        else{
          window.location.href = "/#/404"; return;
        } 
      } 
    }

    //this.loadGraphs();
  },
  methods: {
    showChallenge () {
      router.push({path: '/challenges/'+this.challenge.id+'/view'});
    },
    async loadGraphs(){
        // await this.$refs.graphPercent.loadGraph(this.graphs_percent);
        //await this.$refs.graphDonout.loadGraph([]);
        //await this.$refs.gaugePercentGraph.loadGraph([]);
        //await this.$refs.gaugePercentGraph2.loadGraph([]);
        //await this.$refs.graphGauge.loadGraph("");
        //await this.$refs.doubleBarHorizontalGraph.loadGraph("");
        //await this.$refs.doubleBarHorizontalGraph2.loadGraph("");
        //await this.$refs.doubleBarVerticalGraph.loadGraph("");
    },
    setColor (value) {
      let $color
      if (value > 0) {
        $color = 'good';        
      } else {
        $color = 'bad';
      }
      return $color
    }
  }
}
</script>

<style>
  #dates .card-body{
    padding: 0px important!;
  }

  .text-value{
    font-size: 32px;
    text-align: right;
  }

  .text-muted{
    font-size: 18px;
    text-align: right;
  }

  .widget{
    .card-body{
      div:nth-child(2) {
        width: 100%;
      }
    }
  }

  #total_balance{
    .card-body{
      div:nth-child(2) {
        width: 100%;
      }
    }
  }
  .good{
    background-color: #b0d136;
    color: black;
  }

  .bad{
    background-color: #f04922;
    color: white;
  }
</style>
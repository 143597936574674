<template>
    <div>
        <div class="text-center">
            <div style="font-size: 16px;"><strong>{{title}}</strong></div>
        </div>        
        <div style="width: 100%; height: 300px;" ref="gaugeChart">                            
        </div>
    </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from "@amcharts/amcharts5/xy";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5index from "@amcharts/amcharts5/index";
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
// import ws from '@/services/graphs';

export default {
    name: 'GaugePercentGraph',
    props: {
        permissions: {
            type: Object,
            default() {
                return {}
            }
        },
        title: {
            type: String,
            default() {
                return ""
            }
        },              
    },
    components : {
	},
    data: function () {
		return {
            items: {},
            root: "",
        }
    },
    computed: {
        
    },
    methods: {
        loadGraph: async function(valuePercent2){
            let valuePercent = 10;
            if (this.root) {
                this.root.dispose();
            }
            this.root = am5.Root.new(this.$refs.gaugeChart);        
            this.root.setThemes([am5themes_Animated.new(this.root)]);

            // Se inicializa el contenedor (al parecer puede contener varias graficas este mismo contenedor NO PROBADO)
            let chart = this.root.container.children.push(
            // Se inicializa el tipo de grafica
                am5radar.RadarChart.new(this.root, {
                    panX: false,
                    panY: false,
                    startAngle: 270,
                    endAngle: 630
                })
            );

            chart.getNumberFormatter().set("numberFormat", "#'%'");

            let axisRenderer = am5radar.AxisRendererCircular.new(this.root, {
                innerRadius: -40
            });

            axisRenderer.labels.template.setAll({
                forceHidden: true
            });        

            axisRenderer.grid.template.setAll({
                stroke: this.root.interfaceColors.get("background"),
                visible: true,
                strokeOpacity: 0.8,
                cornerRadius:0
            });

            let xAxis = chart.xAxes.push(
                am5xy.ValueAxis.new(this.root, {
                    maxDeviation: 0,
                    min: 0,
                    max: 100,
                    strictMinMax: true,
                    renderer: axisRenderer
                })
            );

            let axisDataItem = xAxis.makeDataItem({});        

            let clockHand = am5radar.ClockHand.new(this.root, {
                pinRadius: 50,
                radius: am5.percent(100),
                innerRadius: 50,
                bottomWidth: 0,
                topWidth: 0
            });

            clockHand.pin.setAll({
                fillOpacity: 0,
                strokeOpacity: 0.5,
            });

            let bullet = axisDataItem.set(
                "bullet",
                am5xy.AxisBullet.new(this.root, {
                    sprite: clockHand
                })
            );     
        
            xAxis.createAxisRange(axisDataItem);


            let label = chart.radarContainer.children.push(
                    am5.Label.new(this.root, {
                        centerX: am5.percent(50),
                        textAlign: "center",
                        centerY: am5.percent(50),
                        fontSize: "3em"
                    })
                );

            axisDataItem.set("value", 0);

            bullet.get("sprite").on("rotation", function () {
                let value = axisDataItem.get("value");
                label.set("text", Math.round(value).toString() + "%");
            });

            chart.bulletsContainer.set("mask", undefined);

            let value = Number(valuePercent);
            let color = "#0f9747";
            if(value <= 15){
                color = "#ee1f25";
            }else if(value <= 30){
                color = "#f04922";
            }else if(value <= 45){
                color = "#fdae19";
            }else if(value <= 60){
                color = "#f3eb0c";
            }else if(value <= 75){
                color = "#b0d136";
            }else if(value <= 90){
                color = "#54b947";
            }

            let axisRange0 = xAxis.createAxisRange(
                    xAxis.makeDataItem({
                        above: true,
                        value: 0,
                        endValue: 50,
                    })
                );

            axisRange0.get("axisFill").setAll({
                visible: true,
                fill: color,
                cornerRadius: 0
                });

            axisRange0.get("label").setAll({
                forceHidden: true
            });

            let axisRange1 = xAxis.createAxisRange(
                xAxis.makeDataItem({
                    above: true,
                    value: 50,
                    endValue: 100
                })
            );
            let color2 = "#E0E0E0"
            if(valuePercent > 0){
                color2 = "#E0E0E0"
            }
            axisRange1.get("axisFill").setAll({
                visible: true,
                fill:color2,
            });

            axisRange1.get("label").setAll({
                forceHidden: false
            });        

            axisDataItem.animate({
                key: "value",
                to: value,
                duration: 500,
                easing: am5.ease.out(am5.ease.cubic)
            });

            axisRange0.animate({
                key: "endValue",
                to: value,
                duration: 500,
                easing: am5.ease.out(am5.ease.cubic)
            });

            axisRange1.animate({
                key: "value",
                to: value,
                duration: 500,
                easing: am5.ease.out(am5.ease.cubic)
            });

            chart.appear(1000, 100);
        },
    },
  	mounted: async function(){    
    },
    beforeDestroy() {
        if (this.root) {
            this.root.dispose();
        }
  }
}

</script>
